import React from "react";
import { Link, graphql } from "gatsby";

// Components
import Layout from "../components/layout.js";

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allAsciidoc;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`;

  return (
    <Layout title={`Tag ${tag}`}>
      <article>
        <h1>{tagHeader}</h1>
        <ul>
          {edges.map(({ node }) => {
            const {
              fields: { slug: path },
              document: { title },
            } = node;
            return (
              <li key={path}>
                <Link to={path}>{title}</Link>
              </li>
            );
          })}
        </ul>
        <Link to="/tags">All tags</Link>
        <Link to="/blog">Archive</Link>
      </article>
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    allAsciidoc(
      limit: 2000
      sort: { fields: [revision___date], order: DESC }
      filter: { fields: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          document {
            title
          }
        }
      }
    }
  }
`;
